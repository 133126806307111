import React from "react"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-react-intl"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import {size, spacing, color, ensembles, composers } from "../constants"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Block = styled.div`
  background-color: ${color.COLOR2};

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;

  & div:first-of-type {
    flex: 0 1 350px;
  }
  & p {
    flex: 1 1;
    padding: 5em;
    margin-bottom: 0;
  }
  & a {
    color: white;
  }
  @media(max-width: 930px) {
    & div:first-of-type {
      max-width: 100%;
    }
    flex-direction: column;
  }
`

const CallToAction = styled(Block)`
  margin-top: 4em;
  & div:first-of-type {
    height: 150px;
  }
  & p {
    padding: 2em;
  }
  & div:nth-of-type(2) {
    flex: 0 1 100px;
    & img {
      width: 50px;
      height: 50px;
      margin: auto;
    }
  }

  &:hover {
    background-color: ${color.COLOR2INTENSE};
    -webkit-transition: background-color 0.25s linear;
    -ms-transition: background-color 0.25s linear;
    transition: background-color 0.25s linear;

    & div:nth-of-type(2) {
      transition: transform 0.5s ease-in-out;
      transform: translateX(20px);
    }
  }
  @media(max-width: 930px) {
    div:nth-of-type(2) {
      display: none;
    }
  }
`

const CustomLink = styled.a`
  display: inline-block;
  margin: 2em 2em 0 0;
  padding: 1em;
  background-color: ${color.COLOR2};
  color: white;
  text-decoration: none;
  &:hover {
    background-color: ${color.COLOR2INTENSE};
    -webkit-transition: background-color 0.25s linear;
    -ms-transition: background-color 0.25s linear;
    transition: background-color 0.25s linear;
  }
`

const CustomLinkDisabled = styled.span`
  display: inline-block;
  margin: 2em 2em 0 0;
  padding: 1em;
  background-color: ${color.COLOR2};
  color: white;
  text-decoration: none;
`

const PreistraegerPage = () => {
  const intl = useIntl()
  return (
    <>
    
    <Layout title={intl.formatMessage({ id: "preistrager" }) + " 2021"}>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "preistrager" }) + " 2021"}
        keywords={[`gatsby`, `application`, `react`]}
      />
        <h2><FormattedMessage id="gratulation" /></h2>
        <p>
          <FormattedMessage id="wettbewerb-2021-1"
          values={{
            a1: msg => (
              <a target="_blank" rel="noopener noreferrer"  href="https://www.muvac.com/de/vacancies"
                css={css`color: white; transition: opacity 0.25s; &:hover {opacity: 0.5;}`}>
                {msg}
              </a>
            ),
            b: msg => (
              <b>{msg}</b>
            ),
            br: <><br/></>
          }} />
        </p>
        <p>
          <FormattedMessage id="wettbewerb-2021-2"
          values={{
            a1: msg => (
              <a target="_blank" rel="noopener noreferrer"  href="https://www.muvac.com/de/vacancies"
                css={css`color: white; transition: opacity 0.25s; &:hover {opacity: 0.5;}`}>
                {msg}
              </a>
            ),
            b: msg => (
              <b>{msg}</b>
            ),
            br: <><br/></>
          }} />
        </p>
        <p>
          <FormattedMessage id="wettbewerb-2021-3"
          values={{
            a1: msg => (
              <a target="_blank" rel="noopener noreferrer"  href="https://www.muvac.com/de/vacancies"
                css={css`color: white; transition: opacity 0.25s; &:hover {opacity: 0.5;}`}>
                {msg}
              </a>
            ),
            b: msg => (
              <b>{msg}</b>
            ),
            br: <><br/></>
          }} />
        </p>
        <p>
          <FormattedMessage id="wettbewerb-2021-4"
          values={{
            a1: msg => (
              <a target="_blank" rel="noopener noreferrer"  href="https://www.muvac.com/de/vacancies"
                css={css`color: white; transition: opacity 0.25s; &:hover {opacity: 0.5;}`}>
                {msg}
              </a>
            ),
            b: msg => (
              <b>{msg}</b>
            ),
            br: <><br/></>
          }} />
        </p>
        <p>
          <FormattedMessage id="wettbewerb-2021-5"
          values={{
            a1: msg => (
              <a target="_blank" rel="noopener noreferrer"  href="https://www.muvac.com/de/vacancies"
                css={css`color: white; transition: opacity 0.25s; &:hover {opacity: 0.5;}`}>
                {msg}
              </a>
            ),
            b: msg => (
              <b>{msg}</b>
            ),
            br: <><br/></>
          }} />
        </p>
        <p>
          <FormattedMessage id="wettbewerb-2021-6"
          values={{
            a1: msg => (
              <a target="_blank" rel="noopener noreferrer"  href="https://www.muvac.com/de/vacancies"
                css={css`color: white; transition: opacity 0.25s; &:hover {opacity: 0.5;}`}>
                {msg}
              </a>
            ),
            b: msg => (
              <b>{msg}</b>
            ),
            br: <><br/></>
          }} />
        </p>

        {/* <p>
          <a target="_blank" rel="noopener noreferrer" href={`/download/${intl.formatMessage({ id: "reglement-url" })}.pdf`}>
            <FormattedMessage id="reglement" /> 2021
          </a>
        </p> */}

    

      {/* <h1>
        <FormattedMessage id="hello" />
      </h1>
      <p>
        <FormattedMessage id="welcome" />
      </p>
      <p>
        <FormattedMessage id="build" />
      </p>

      <Link to="/page-2/">
        <FormattedMessage id="go_page2" />
      </Link> */}
    </Layout>
    </>
  )
}

export default PreistraegerPage